import React from 'react';
import { AppRegistry } from 'react-native';
import { AppContainer } from 'react-hot-loader';
import App from '@pss/shared/AppRoot';
import installServiceWorker from './serviceWorker';

// App registration and rendering
const renderApp = () => (
  <AppContainer>
    <App />
  </AppContainer>
);

AppRegistry.registerComponent('DistrictPSS', () => renderApp);

// Registration for HMR
if (module.hot) {
  module.hot.accept();

  const renderHotApp = () => (
    <AppContainer>
      <App />
    </AppContainer>
  );

  AppRegistry.registerComponent('DistrictPSS', () => renderHotApp);
}

// Run App
AppRegistry.runApplication('DistrictPSS', {
  rootTag: document.getElementById('root'),
});

// install service worker
installServiceWorker();
