import { UPDATE_FOUND_ACTION_MESSAGE_TYPE } from '@pss/shared/common/constants/serviceWorkerConstants';

export default function installServiceWorker() {
  // TODO: find a better way to pass this function
  window.__UPDATE_SERVICE_WORKER__ = null;

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(registration => {
          // make update SW function as global function
          window.__UPDATE_SERVICE_WORKER__ = function updateSW() {
            registration.update();
          };

          // hooks for update SW lifecycle
          registration.onupdatefound = () => {
            const newServiceWorker = registration.installing;
            newServiceWorker.onstatechange = () => {
              switch (newServiceWorker.state) {
                case 'installed':
                  if (navigator.serviceWorker.controller) {
                    // Update found and installed!
                    window.postMessage(
                      JSON.stringify({ type: UPDATE_FOUND_ACTION_MESSAGE_TYPE })
                    );
                  }
                  break;
                default:
                  break;
              }
            };
          };
        });
    });
  }
}
